import { FC, SyntheticEvent, useContext, useEffect, useState } from "react";
// import { useStyles } from "./ProductPage.styles";
import { useGlobalStyles } from "../../../utils/theme";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ALL_CLASSIC_TOURS,
  ALL_INTERACTIVE_TOURS,
  ALL_PRODUCTS,
  IClassicToursData,
  IInteractiveToursData,
  IProduct,
  IProductData,
  IProductVars,
  IProductsData,
  ONE_PRODUCT,
} from "../../../apollo/queries";
import { ContextProvider, ERouterPaths, useForm } from "../../../utils";
import { initialInputData } from "./ProductPage.inputs";
import {
  CREATE_PRODUCT,
  ICreateProductData,
  ICreateProductVars,
  IUpdateProductData,
  IUpdateProductVars,
  UPDATE_PRODUCT,
} from "../../../apollo/mutations";
import {
  DataHandlerComponent,
  InfoShowcase,
  LoadingBackdrop,
  PageLayout,
  PublishedShowcase,
  NumberFormatCustom,
} from "../../../components";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Button,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

interface ITour {
  id: string;
  title: string;
  published: boolean;
  __typename: "ClassicTour" | "InteractiveTour" | undefined;
}

export const ProductPage: FC = () => {
  // const { classes } = useStyles();
  const { cx, classes: globalClasses } = useGlobalStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [allTours, setAllTours] = useState<ITour[]>([]);
  const { user } = useContext(ContextProvider);

  const isEdit = id !== undefined;

  const [selectedId, setSelectedId] = useState<{
    id: string | undefined;
    __typename: "ClassicTour" | "InteractiveTour" | undefined;
  }>({
    id: undefined,
    __typename: undefined,
  });

  const [queryOneProduct, { loading, data, error }] = useLazyQuery<
    IProductData,
    IProductVars
  >(ONE_PRODUCT);

  const [
    queryAllClassicTours,
    {
      loading: loadingClassicTours,
      data: dataClassicTours,
      error: errorClassicTours,
    },
  ] = useLazyQuery<IClassicToursData>(ALL_CLASSIC_TOURS, {
    fetchPolicy: "network-only",
  });

  const [
    queryAllInteractiveTours,
    {
      loading: loadingInteractiveTours,
      data: dataInteractiveTours,
      error: errorInteractiveTours,
    },
  ] = useLazyQuery<IInteractiveToursData>(ALL_INTERACTIVE_TOURS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loadingInteractiveTours && !loadingClassicTours) {
      let classicTours: ITour[] = [];
      if (dataClassicTours?.allClassicTours.length) {
        classicTours = dataClassicTours?.allClassicTours.map((item) => {
          return {
            id: item.id,
            title: item.locale?.title || `Unnamed classic tour ${item.id}`,
            published: item.published,
            //@ts-ignore
            __typename: item.__typename,
          } as ITour;
        });
      }
      let interactiveTours: ITour[] = [];
      if (dataInteractiveTours?.allInteractiveTours.length) {
        interactiveTours = dataInteractiveTours?.allInteractiveTours.map(
          (item) => {
            return {
              id: item.id,
              title:
                item.locale?.title || `Unnamed interactive tour ${item.id}`,
              published: item.published,
              //@ts-ignore
              __typename: item.__typename,
            } as ITour;
          }
        );
      }
      setAllTours(classicTours.concat(interactiveTours));
    }
  }, [
    loadingClassicTours,
    loadingInteractiveTours,
    dataClassicTours,
    dataInteractiveTours,
  ]);

  const {
    inputFields,
    validateForm,
    didValuesChange,
    getFormValuesFromFetchedData,
    handleDataToVar,
    resetFields,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [createProductMutation, { loading: loadingCreateProductMutation }] =
    useMutation<ICreateProductData, ICreateProductVars>(CREATE_PRODUCT, {
      onCompleted: (data) => {
        enqueueSnackbar(`New product created`, {
          variant: "success",
        });
        navigate(`/${ERouterPaths.PRODUCTS}`);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        const existingListData: IProductsData | null = cache.readQuery({
          query: ALL_PRODUCTS,
        });
        if (data?.createProduct) {
          const newStationData: IProduct = {
            ...data.createProduct,
          };
          cache.writeQuery({
            query: ALL_PRODUCTS,
            data: {
              allProducts: existingListData?.allProducts
                ? [newStationData, ...existingListData.allProducts]
                : [newStationData],
            },
          });
        }
      },
    });

  const [updateProductMutation, { loading: loadingUpdateProductMutation }] =
    useMutation<IUpdateProductData, IUpdateProductVars>(UPDATE_PRODUCT, {
      onCompleted: (data) => {
        enqueueSnackbar(`Product updated`, {
          variant: "success",
        });
        resetFields(["price"], true);
      },

      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    });

  useEffect(() => {
    if (id) {
      queryOneProduct({ variables: { id: +id } });
    } else {
      queryAllClassicTours();
      queryAllInteractiveTours();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data?.oneProduct) {
      getFormValuesFromFetchedData(
        data.oneProduct,
        [
          {
            fromDataProperty: "price",
            toFormProperty: "price",
          },
        ],
        false,
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.oneProduct]);

  const handleUpdateProduct = () => {
    if (validateForm(["price"], true)) {
      if (isEdit && id) {
        updateProductMutation({
          variables: {
            id: +id,
            data: {
              price: handleDataToVar("price", "number", false),
            },
          },
        });
      } else {
        enqueueSnackbar("ID not found!", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Required fields are not set", {
        variant: "error",
      });
    }
  };

  const handleCreateProduct = () => {
    if (validateForm(["price"], true)) {
      if (!isEdit) {
        const whichTour =
          selectedId.__typename === "ClassicTour"
            ? { classicTourId: selectedId.id }
            : { interactiveTourId: selectedId.id };
        createProductMutation({
          variables: {
            //TODO: Fix typescript
            //@ts-ignore
            data: {
              price: inputFields.price.inputProps.value,
              ...whichTour,
            },
          },
        });
      } else {
        enqueueSnackbar("ID not found!", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Required fields are not set", {
        variant: "error",
      });
    }
  };

  const onChangeTourAutocomplete = (
    event: SyntheticEvent<Element, Event>,
    value: ITour | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ITour> | undefined
  ) => {
    if (value) {
      setSelectedId({ id: value.id, __typename: value.__typename });
    }
  };

  console.log("INPUT: ", inputFields.price.values);

  return (
    <PageLayout displayFlex>
      <Paper className={globalClasses.paperRoot}>
        <div
          className={cx(
            globalClasses.paperTitle,
            globalClasses.justifySpaceBetween
          )}
        >
          <Typography variant="h6">
            {isEdit ? "Edit product" : "Add a new product"}
          </Typography>
        </div>
        <div className={globalClasses.paperContainer}>
          <DataHandlerComponent
            hasData={Boolean(!isEdit || (isEdit && data?.oneProduct))}
            error={Boolean(error || errorClassicTours || errorInteractiveTours)}
            loading={loading || loadingClassicTours || loadingInteractiveTours}
          >
            {!isEdit ? (
              <>
                <Typography color="textSecondary">
                  To add a new product select a tour and enter its price.
                </Typography>

                <Autocomplete
                  id="connect-station"
                  options={allTours}
                  loading={loadingClassicTours || loadingInteractiveTours}
                  limitTags={50}
                  getOptionLabel={(option) =>
                    option.title || `Unnamed tour ${option.id}`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id &&
                    option.__typename === value.__typename
                  }
                  onChange={onChangeTourAutocomplete}
                  value={
                    allTours.find(
                      (x) =>
                        selectedId.id === x.id &&
                        selectedId.__typename === x.__typename
                    ) || null
                  }
                  renderOption={(props, option) => {
                    return (
                      <MenuItem {...props}>
                        <ListItemText>{option.title}</ListItemText>
                        <ListItemIcon>
                          <PublishedShowcase published={option.published} />
                        </ListItemIcon>
                      </MenuItem>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tour"
                      required={true}
                      helperText="Select a tour to add a new product."
                      margin="normal"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </>
            ) : null}

            <TextField
              margin="normal"
              {...inputFields.price.inputProps}
              autoFocus
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  onChange: inputFields.price.inputProps.onChange,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography>
                      {
                        user?.activePersonRoleSubject?.subject?.businessModel
                          ?.currency?.isoCode
                      }
                    </Typography>
                  </InputAdornment>
                ),
              }}
              // inputProps={{
              //   maxLength: INPUT_STATION_TITLE_MAX_LENGTH,
              // }}
            />
            {/* <NumericFormat
              // {...inputFields.price.inputProps}
              // {...props}
              // value={value}
              name={inputFields.price.inputProps.name}
              // mask={mask}
              customInput={PriceTextField}
              InputProps={{ ...inputFields.price.inputProps, fullWidth: true }}
              // prefix={'$'}
              // format={format || null}
              // type="text"
              // thousandSeparator={thousandSeparator ? ' ' : null}
              // onValueChange={({ value: v }) => onChange({ target: { name, value: v } })} */}
            {/* /> */}

            <InfoShowcase title="The added product and its price are shown only on receipts when you unlock access to content via reception." />
            <InfoShowcase title="The management of products and their prices used on Google Play and App Store is coordinated with the Naratour team." />
            {isEdit ? (
              <InfoShowcase title="Set price to 0 to make it FREE" />
            ) : null}
          </DataHandlerComponent>
        </div>
        <div className={globalClasses.paperButtons}>
          <div className={globalClasses.paperButtonsLeft}></div>
          <div className={globalClasses.paperButtonsRight}>
            {isEdit ? (
              <Button
                onClick={handleUpdateProduct}
                variant="contained"
                disabled={!didValuesChange(["price"])}
              >
                Update
              </Button>
            ) : (
              <Button
                onClick={handleCreateProduct}
                variant="contained"
                disabled={!didValuesChange(["price"])}
              >
                Create new Product
              </Button>
            )}
            {/* {activeTab === "2" && isEdit ? (
                <Button onClick={handleUpdateOther} variant="contained">
                  Update Other
                </Button>
              ) : null} */}
          </div>
        </div>
      </Paper>
      <LoadingBackdrop
        loading={loadingCreateProductMutation || loadingUpdateProductMutation}
      />
    </PageLayout>
  );
};
