import { gql } from "@apollo/client";

export const F_ACTIVE_PERSON_ROLE_SUBJECT = gql`
  fragment FActivePersonRoleSubject on PersonRoleSubject {
    id
    subject {
      id
      locale {
        id
        name
      }
      businessModel {
        id
        canSell
        model
        currency {
          id
          isoCode
        }
      }
    }
    role {
      id
      access
      locale {
        id
        name
      }
    }
  }
`;
