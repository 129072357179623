import { ERoleAccess, ERouterPaths } from "../../utils";
import {
  PermMediaOutlined as PermMediaOutlinedIcon,
  PersonOutline as PersonOutlineIcon,
  SubtitlesOutlined as SubtitlesOutlinedIcon,
  TourOutlined as TourOutlinedIcon,
  // AssistantPhotoOutlined as AssistantPhotoOutlinedIcon,
  SportsEsportsOutlined as SportsEsportsOutlinedIcon,
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
  PlagiarismOutlined as PlagiarismOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  CreditScoreOutlined as CreditScoreOutlinedIcon,
  PriceChangeOutlined as PriceChangeOutlinedIcon,
} from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";

export interface INavbarData {
  id: string;
  title: string;
  route: ERouterPaths;
  icon: OverridableComponent<SvgIconTypeMap>;
  access: ERoleAccess[];
  requiresCanSell?: boolean;
  external?: boolean;
  group: string;
}

export const navbarData: INavbarData[] = [
  {
    id: "0",
    title: "Interactive tours",
    route: ERouterPaths.INTERACTIVE_TOURS,
    icon: SportsEsportsOutlinedIcon,
    access: [
      ERoleAccess.SUPER_ADMIN,
      ERoleAccess.OWNER,
      ERoleAccess.MANAGER,
      ERoleAccess.ADMIN_PLUS,
      ERoleAccess.ADMIN,
    ],
    group: "tours",
  },
  {
    id: "1",
    title: "Classic tours",
    route: ERouterPaths.CLASSIC_TOURS,
    icon: TourOutlinedIcon,
    access: [
      ERoleAccess.SUPER_ADMIN,
      ERoleAccess.OWNER,
      ERoleAccess.MANAGER,
      ERoleAccess.ADMIN_PLUS,
      ERoleAccess.ADMIN,
    ],
    group: "tours",
  },
  // {
  //   id: "2",
  //   title: "Stations",
  //   route: ERouterPaths.STATIONS,
  //   icon: AssistantPhotoOutlinedIcon,
  //   access: [
  //     ERoleAccess.SUPER_ADMIN,
  //     ERoleAccess.OWNER,
  //     ERoleAccess.MANAGER,
  //     ERoleAccess.ADMIN_PLUS,
  //     ERoleAccess.ADMIN,
  //   ],
  //   group: "tours",
  // },
  {
    id: "3",
    title: "About us",
    route: ERouterPaths.ABOUT_US,
    icon: SubtitlesOutlinedIcon,
    access: [ERoleAccess.SUPER_ADMIN, ERoleAccess.OWNER, ERoleAccess.MANAGER],
    group: "tours",
  },
  {
    id: "4",
    title: "Reception",
    route: ERouterPaths.RECEPTION,
    icon: ShoppingCartOutlinedIcon,
    requiresCanSell: true,
    access: [
      ERoleAccess.SUPER_ADMIN,
      ERoleAccess.OWNER,
      ERoleAccess.MANAGER,
      ERoleAccess.ADMIN_PLUS,
      ERoleAccess.RECEPTION,
    ],
    group: "products",
  },
  {
    id: "5",
    title: "Receipts",
    route: ERouterPaths.RECEIPTS,
    icon: CreditScoreOutlinedIcon,
    requiresCanSell: true,
    access: [
      ERoleAccess.SUPER_ADMIN,
      ERoleAccess.OWNER,
      ERoleAccess.MANAGER,
      ERoleAccess.ADMIN_PLUS,
      ERoleAccess.RECEPTION,
    ],
    group: "products",
  },
  {
    id: "6",
    title: "Products",
    route: ERouterPaths.PRODUCTS,
    icon: PriceChangeOutlinedIcon,
    requiresCanSell: true,
    access: [ERoleAccess.SUPER_ADMIN, ERoleAccess.OWNER, ERoleAccess.MANAGER],
    group: "products",
  },
  {
    id: "7",
    title: "Media",
    route: ERouterPaths.MEDIA,
    icon: PermMediaOutlinedIcon,
    access: [
      ERoleAccess.SUPER_ADMIN,
      ERoleAccess.OWNER,
      ERoleAccess.MANAGER,
      ERoleAccess.ADMIN_PLUS,
      ERoleAccess.ADMIN,
    ],
    group: "other",
  },
  {
    id: "8",
    title: "Administrators",
    route: ERouterPaths.ADMINISTRATORS,
    icon: PersonOutlineIcon,
    access: [ERoleAccess.SUPER_ADMIN, ERoleAccess.OWNER, ERoleAccess.MANAGER],
    group: "other",
  },
  {
    id: "9",
    title: "FAQ",
    route: ERouterPaths.FAQ,
    icon: HelpOutlineOutlinedIcon,
    access: [
      ERoleAccess.SUPER_ADMIN,
      ERoleAccess.OWNER,
      ERoleAccess.MANAGER,
      ERoleAccess.ADMIN_PLUS,
      ERoleAccess.ADMIN,
      ERoleAccess.RECEPTION,
    ],
    group: "help",
    external: true,
  },
  {
    id: "10",
    title: "Guidelines",
    route: ERouterPaths.GUIDELINES,
    icon: PlagiarismOutlinedIcon,
    access: [
      ERoleAccess.SUPER_ADMIN,
      ERoleAccess.OWNER,
      ERoleAccess.MANAGER,
      ERoleAccess.ADMIN_PLUS,
      ERoleAccess.ADMIN,
      ERoleAccess.RECEPTION,
    ],
    group: "help",
  },
];
