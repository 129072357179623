import { FC, useContext } from "react";
import { useStyles } from "./Navbar.styles";
import { ContextProvider } from "../../utils";
import { useSpring, animated } from "@react-spring/web";
import { NavbarItem } from "./components/navbarItem/NavbarItem";
import { navbarData } from "./Navbar.data";

const HEADER_MIN_WIDTH = 64;
const HEADER_MAX_WIDTH = 256;

export const Navbar: FC = () => {
  const { classes, cx } = useStyles();
  const { user } = useContext(ContextProvider);

  const [springs, api] = useSpring(() => ({
    from: { width: HEADER_MIN_WIDTH },
  }));

  const onMouseEnter = () => {
    api.start({
      to: {
        width: HEADER_MAX_WIDTH,
      },
    });
  };

  const onMouseExit = () => {
    api.start({
      to: {
        width: HEADER_MIN_WIDTH,
      },
    });
  };

  if (!user?.activePersonRoleSubject?.id) {
    return null;
  }

  return (
    <animated.nav
      className={cx(classes.root)}
      style={springs}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseExit}
    >
      {navbarData
        .filter(
          (x) =>
            x.access.includes(user.activePersonRoleSubject!.role.access) &&
            user.activePersonRoleSubject!.subject.businessModel?.id &&
            (!x.requiresCanSell ||
              user.activePersonRoleSubject!.subject.businessModel.canSell)
        )
        .reduce<Array<JSX.Element>>((acc, item, i, filteredData) => {
          const shouldHaveDivider =
            i < filteredData.length - 1 &&
            item.group !== filteredData[i + 1].group;

          acc.push(
            <NavbarItem key={item.id} data={item} divider={shouldHaveDivider} />
          );
          return acc;
        }, [])}
    </animated.nav>
  );
};
