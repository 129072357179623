import { useEffect, useState } from "react";
import { BroadcastChannel, createLeaderElection } from "broadcast-channel";
import { App } from "./App";
import { LoadingBackdrop, OneTabOnlyMessage } from "./components";

export const BroadcastApp = () => {
  const [canRender, setCanRender] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const initChannel = async () => {
      const channel = new BroadcastChannel("oneTabOnly");
      const elector = createLeaderElection(channel);
      elector.awaitLeadership().then(() => {
        setCanRender(true);
      });
      if (!elector.isLeader) {
        setCanRender(false);
      }

      elector.onduplicate = async () => {
        console.log("duplicate");
        await elector.die();
        setCanRender(false);
      };
    };

    initChannel();
  }, []);

  return canRender === true ? (
    <App />
  ) : canRender === false ? (
    <OneTabOnlyMessage />
  ) : (
    <LoadingBackdrop loading />
  );
};
