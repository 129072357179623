export const minutesToHoursAndMinutes = (
  minutes: number | undefined | null
) => {
  if (minutes === undefined || minutes === null) {
    return "N/A";
  }
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  if (hours) {
    return `${hours}h${minutesLeft ? ` ${minutesLeft}m` : ""}`;
  } else {
    return `${minutesLeft}m`;
  }
};

export const isObject = (o: any, strict: boolean = true) => {
  if (o === null || o === undefined) {
    return false;
  }
  const instanceOfObject = o instanceof Object;
  const typeOfObject = typeof o === "object";
  const constructorUndefined = o.constructor === undefined;
  const constructorObject = o.constructor === Object;
  const typeOfConstructorObject = typeof o.constructor === "function";
  let r;
  if (strict === true) {
    r =
      (instanceOfObject || typeOfObject) &&
      (constructorUndefined || constructorObject);
  } else {
    r = constructorUndefined || typeOfConstructorObject;
  }
  return r;
};

export const joinClassNames = (classNames: string[]) => {
  return classNames.join(" ");
};

export const displayCurrency = (amount: number, currency?: string) => {
  if (!amount) {
    return "FREE";
  }
  const value = (amount * 0.01).toFixed(2);

  if (currency) {
    return `${value} ${currency}`;
  } else {
    return `${value}`;
  }
};

export const getDiscountedPrice = (price: number, discount: number) => {
  return (price - (price * discount) / 100).toFixed(2);
};
