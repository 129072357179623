import { gql } from "@apollo/client";
import { F_ACTIVE_PERSON_ROLE_SUBJECT } from "../../queries/fragments";
import { ERoleAccess } from "../../../utils";

export interface IActivePersonRoleSubject {
  id: string;
  subject: {
    id: string;
    locale: {
      id: string;
      name: string;
    } | null;
    businessModel: {
      id: string;
      canSell: boolean;
      model: string;
      currency: {
        id: string;
        isoCode: string;
      };
    };
  };
  role: {
    id: string;
    access: ERoleAccess;
    locale: {
      id: string;
      name: string;
    } | null;
  };
}

export interface ISwitchPersonRoleSubjectData {
  switchPersonRoleSubject: IActivePersonRoleSubject;
}
export interface ISwitchPersonRoleSubjectVars {
  id: number;
}

export const SWITCH_PERSON_ROLE_SUBJECT = gql`
  ${F_ACTIVE_PERSON_ROLE_SUBJECT}
  mutation switchPersonRoleSubject($id: ID!) {
    switchPersonRoleSubject(id: $id) {
      ...FActivePersonRoleSubject
    }
  }
`;
