import { FC, forwardRef } from "react";
import { NumericFormatProps, NumberFormatBase } from "react-number-format";

type TProps = NumericFormatProps;

export const NumberFormatCustom: FC<TProps> = forwardRef((props, ref) => {
  //@ts-ignore
  const { onChange, ...other } = props;

  const currencyFormatter = (value: string) => {
    if (value === "") {
      return "";
    }

    const v = +value;

    if (v === 0) {
      return "0";
    }

    if (!Number(v)) return "";

    const amount = new Intl.NumberFormat("pt-BR", {
      // style: "",
      // currency: "EUR",
      minimumFractionDigits: 2,
    }).format(v / 100);

    return `${amount}`;
  };

  return (
    <NumberFormatBase
      {...other}
      getInputRef={ref}
      format={currencyFormatter}
      onValueChange={(values) => {
        console.log(values);

        const fakeEvent = {
          target: {
            name: props.name,
            value: values.value,
          },
        } as React.ChangeEvent<HTMLInputElement>;
        onChange?.(fakeEvent);
      }}

      // thousandSeparator=""
      // decimalSeparator="."
      // valueIsNumericString
      // allowNegative={false}
      // allowedDecimalSeparators={[".", ","]}
      // decimalScale={2}
      // fixedDecimalScale
    />
  );
});
